import React from 'react';
import ReactComponent from "../../ReactComponent";
import {DotLoader} from "react-spinners";
import {Query} from "react-apollo";
import {loader as gqlLoader} from "graphql.macro"

import {withSitecoreContext} from "@sitecore-jss/sitecore-jss-react";
import './filterCategories.scss';
import './FilterCategoriesByPage.scss'
import { handleFilterClick } from '../Tagging';



const FilterCategoriesQuery = gqlLoader('./filterCategories.graphql');
const FilterListQuery = gqlLoader('./filtersList.graphql');

class FilterCategories extends ReactComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            itemdata: [],
            items: [],
            urlFilter: '',
            filters: [],
            templateChoose: null,
            categories: [],
            tempCat: [],
            findCat: false,
            byUrl: '',
            preFiltered: false,
        };
    }

    componentDidMount() {
        const url = window.location.href;

        if (
            url.includes('?categoryId=') ||
            url.includes('?brandId=') ||
            url.includes('?tagId=') ||
            url.includes('?ingredientId=') ||
            url.includes('?advantagesId=')
        ) {
            const urlClean = window.location.href.substring(window.location.href.indexOf('=') + 1);

            this.setState({
                byUrl: urlClean,
            });
        }
    }

    componentDidUpdate(nextProps) {
        if (nextProps.sitecoreContext.route.name !== this.props.sitecoreContext.route.name) {
            this.setState({ preFiltered: false });
        }
    }

    getItemForHandleClick(id, name) {
        for (let i = 0; i < this.state.categories.length; i++) {
            if (this.state.categories[i].item.id === id) {
                this.handleClick(this.state.categories[i]);
            }
        }
        if (name) {
            this.state.tempCat.push([name, id]);
        }
    }

    changeFiltersOnSameSelect(incomingValue, targetValue) {
        let selected = document.getElementById(incomingValue);

        for (let d = 0; d < selected.options.length; d++) {
            for (let c = 0; c < this.state.filters.length; c++) {
                if (this.state.filters[c].item.id === selected.options[d].value) {
                    for (let i = 0; i < this.state.categories.length; i++) {
                        if (this.state.categories[i].item.id === targetValue) {
                            this.state.filters.splice(c, 1, this.state.categories[i]);
                            this.pushCategories(this.state.categories[i]);
                        }
                    }
                }
            }
        }
    }

    pushCategories(item) {
        if (item.item.__typename === 'ProductBrandTemplate') {
            this.props.onFiltersChange({ items: this.state.filters, template: 'folderProductBrand' });
        } else if (item.item.__typename === 'ProductCategoryTemplate') {
            this.props.onFiltersChange({ items: this.state.filters, template: 'folderProductCategory' });
        } else if (item.item.__typename === 'ProductTagTemplate') {
            this.props.onFiltersChange({ items: this.state.filters, template: 'folderProductTag' });
        } else if (item.item.__typename === 'ProductIngredientTemplate') {
            this.props.onFiltersChange({ items: this.state.filters, template: 'folderProductIngredient' });
        } else if (item.item.__typename === 'ProductAdvantageTemplate') {
            this.props.onFiltersChange({ items: this.state.filters, template: 'folderProductAdvantage' });
        } else {
            this.props.onFiltersChange({ items: this.state.filters, template: 'folderProductBrand' });
        }
    }

    getItem(e, categoryName, categoryId) {
        if (e.target.value === '__default__default') {
            for (let z = 0; z < this.state.tempCat.length; z++) {
                if (this.state.tempCat[z].indexOf(categoryName) !== -1) {
                    this.getItemForHandleClick(this.state.tempCat[z][1], '');
                    if (z === 0) {
                        this.state.tempCat.splice(0, 1);
                    }
                    this.state.tempCat.splice(z, z);
                }
            }
        } else {
            if (this.state.tempCat.length === 0) {
                this.getItemForHandleClick(e.target.value, categoryName);
            } else {
                for (let b = 0; b < this.state.tempCat.length; b++) {
                    if (categoryName === this.state.tempCat[b][0]) {
                        this.state.findCat = true;
                        this.state.tempCat[b][1] = e.target.value;

                        this.changeFiltersOnSameSelect(categoryId, e.target.value);
                    }
                }
                if (this.state.findCat === false) {
                    this.getItemForHandleClick(e.target.value, categoryName);
                }
            }
        }
    }

    handleClickAdd(categTitle, categoryClicked, isPrefiltering) {
        if (this.state.itemdata.indexOf(categoryClicked.item.title.value) === -1 && this.state.filters.indexOf(categoryClicked) === -1) {
            this.state.itemdata.push(categoryClicked.item.title.value);
            this.state.filters.push(categoryClicked);
        }

        this.pushCategories(categoryClicked);

        //GTM
        if (!isPrefiltering) handleFilterClick(categTitle, categoryClicked.item.title.value);

        this.setState({
            byUrl: '',
        });
    }

    handleClickRemove(categoryClicked) {
        this.setState({
            byUrl: '',
        });
        this.state.itemdata.splice(this.state.itemdata.indexOf(categoryClicked.item.title.value), 1);
        this.state.filters.splice(this.state.filters.indexOf(categoryClicked), 1);
        this.pushCategories(categoryClicked);
    }

    loader() {
        return (
            <div className="widget commerce widget_product_categories">
                <div className="sweet-loading" style={{ marginTop: '50px', marginBottom: '50px' }}>
                    <DotLoader sizeUnit={'px'} size={50} color={'#0057B4'} loading={!this.state.isLoaded} />
                </div>
            </div>
        );
    }

    setPreFilter() {
        if (!this.state.preFiltered) {
            let filterOn = this.props.sitecoreContext.route.fields.PfTag.fields.Title.value;

            setTimeout(function () {
                if (typeof document !== 'undefined' && document.getElementById(filterOn)) document.getElementById(filterOn).click();
            }, 100);

            this.setState({ preFiltered: true });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.resetFilters === true) {
            this.setState({
                itemdata: [],
                filters: [],
            });
        }
    }

    parseInteger(x) {
        const parsed = parseInt(x);
        if (isNaN(parsed)) {
            return 0;
        }
        return parsed;
    }

    render() {
        const designVariant = typeof this.props.fields.designVariant === 'undefined' ? '' : this.props.fields.designVariant.value;

        // if(this.state.categories.length > 0) {
        //     this.state.categories = []
        // }

        return (
            <Query
                query={FilterCategoriesQuery}
                variables={{
                    indexname: this.indexName,
                    rootPath: '/sitecore/content/' + this.appName + '/',
                }}
            >
                {({ loading, error, data }) => {
                    if (loading) return this.loader();
                    if (error) return `Error! ${error.message}`;

                    const facetsTab = data;
                    // Sort Facets
                    for (let facets of facetsTab.searchFacets.facets) {
                        facets.values.sort((a, b) => this.parseInteger(a.item.sort.value) - this.parseInteger(b.item.sort.value));
                    }

                    return this.props.fields.filterOn.map((filter, index) => {
                        return (
                            <Query
                                key={index}
                                query={FilterListQuery}
                                variables={{
                                    indexname: this.indexName,
                                    rootPath: '/sitecore/content/' + this.appName + '/',
                                    criteria: filter.id.replace(/-/g, ''),
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return this.loader();
                                    if (error) return `Error! ${error.message}`;

                                    let categories = [];
                                    for (let facets of facetsTab.searchFacets.facets) {
                                        if (
                                            data.criteria.results.items[0].item.template.name === 'folderProductCategory' &&
                                            facets.name === 'productcategories'
                                        ) {
                                            categories = facets.values;
                                        }
                                        if (data.criteria.results.items[0].item.template.name === 'folderProductTag' && facets.name === 'tag') {
                                            categories = facets.values;
                                        }
                                        if (data.criteria.results.items[0].item.template.name === 'folderProductBrand' && facets.name === 'brands') {
                                            categories = facets.values;
                                        }
                                        if (
                                            data.criteria.results.items[0].item.template.name === 'folderProductIngredient' &&
                                            facets.name === 'productingredients'
                                        ) {
                                            categories = facets.values;
                                        }
                                        if (
                                            data.criteria.results.items[0].item.template.name === 'folderProductAdvantage' &&
                                            facets.name === 'productadvantages'
                                        ) {
                                            categories = facets.values;
                                        }
                                    }

                                    let items = [];
                                    let itemsDesign = [];
                                    let facetIn = false;
                                    let selectedCat = false;

                                    // handle pftag system
                                    if (this.props.sitecoreContext.route.fields.PfTag) {
                                        this.setPreFilter();
                                    }

                                    designVariant &&
                                        itemsDesign.push(
                                            <option key={'default'} value={`__default__default`}>
                                                Sélectionnez...
                                            </option>
                                        );

                                    for (let category of data.filters.results.items) {
                                        facetIn = false;
                                        selectedCat = false;
                                        let isPrefiltering =
                                            this.props.sitecoreContext.route.fields.PfTag.fields.Title.value === category.item.title.value
                                                ? true
                                                : false;

                                        if (
                                            categories
                                                .filter((cat) => cat.item.title)
                                                .some(
                                                    (categoryFaceted) =>
                                                        categoryFaceted.item !== null &&
                                                        category.item.title.value === categoryFaceted.item.title.value
                                                )
                                        ) {
                                            facetIn = true;
                                        }
                                        if (
                                            facetIn &&
                                            this.state.itemdata.some((categorySelected) => category.item.title.value === categorySelected)
                                        ) {
                                            selectedCat = true;
                                        }

                                        if (designVariant) {
                                            itemsDesign.push(
                                                <option key={category.item.id} value={`${category.item.id}`}>
                                                    {category.item.title !== undefined && category.item.title.value}
                                                </option>
                                            );
                                        } else {
                                            items.push(
                                                <li
                                                    key={category.item.id.substr(6)}
                                                    className={
                                                        selectedCat || category.item.id.toLowerCase() === this.state.byUrl ? 'selected' : 'available'
                                                    }
                                                >
                                                    {!facetIn ? (
                                                        <span className="notAllowed">{category.item.title && category.item.title.value}</span>
                                                    ) : (
                                                        <React.Fragment>
                                                            <a
                                                                id={category.item.title.value}
                                                                onClick={() =>
                                                                    this.handleClickAdd(
                                                                        data.criteria.results.items[0].item.displayName,
                                                                        category,
                                                                        isPrefiltering
                                                                    )
                                                                }
                                                            >
                                                                {category.item.title && category.item.title.value}
                                                            </a>
                                                            {categories &&
                                                                categories.map((facet, index) => (
                                                                    <span className="count" key={`facetCount-${index}`}>
                                                                        {facetIn &&
                                                                        facet.item &&
                                                                        category.item &&
                                                                        facet.item.id === category.item.id ? (
                                                                            <span className="count">&nbsp;({facet.count})</span>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </span>
                                                                ))}
                                                            {selectedCat || category.item.id.toLowerCase() === this.state.byUrl ? (
                                                                <button onClick={this.handleClickRemove.bind(this, category)} className="crossButton">
                                                                    X
                                                                </button>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </li>
                                            );
                                        }
                                        this.state.categories.push(category);
                                    }

                                    return (
                                        <React.Fragment>
                                            {designVariant ? (
                                                <React.Fragment>
                                                    <div className={'displaySelect'}>
                                                        {data.criteria.results.items.map((cat, idx) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <label key={idx}>{cat.item.displayName} :</label>
                                                                    <select
                                                                        id={cat.item.id}
                                                                        className={`widget commerce product-categories-select ${designVariant}`}
                                                                        onChange={(e) => this.getItem(e, cat.item.displayName, cat.item.id)}
                                                                    >
                                                                        {itemsDesign}
                                                                    </select>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <div className={`widget commerce widget_product_categories`}>
                                                    <h4 className="widget-title">{data.criteria.results.items[0].item.displayName}</h4>
                                                    <ul className="product-categories">{items}</ul>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                }}
                            </Query>
                        );
                    });
                }}
            </Query>
        );
    }
}

export default withSitecoreContext()(FilterCategories);
