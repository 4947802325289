import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { DotLoader } from 'react-spinners';
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import './articleCategoriesNav.scss';
import NavLinkAdv from "../../utils/NavLinkAdv";

const ArticleCategoriesNavigationV2Query = gqlLoader('./query.graphql');


class ArticleCategoriesNavigationV2 extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }

  render() {
    return(
      <div className="cat__custom__article row">  
        
          <Query query={ArticleCategoriesNavigationV2Query} variables={{indexname: this.indexName, rootpath: '/sitecore/content/' + this.appName + '/home'}}>
            {({ loading, error, data}) => {
              if (loading)
                return (
                  <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                    <DotLoader
                      sizeUnit={"px"}
                      size={50}
                      color={'#0057B4'}
                      loading={!this.state.isLoaded}
                    />
                  </div>
                );
              if (error)
                return (
                    <div>Error: {error.message}</div>
                );
              return (
                data && data.product.results.items.map(function (category, idx) {
                  let showBackgroundCover = {display: 'none'};
                  if (category.item !== null) {
                    showBackgroundCover = {backgroundImage: 'url(' + category.item.articleImage.src + ')'};
                  }
                  return (
                    <div style={showBackgroundCover} className="item col-md-4 col-sm-12" key={idx}>
                      <NavLinkAdv to={category.item && category.item.url} title="">
                        <Text tag="span" field={category.item && category.item.articleTitle} className="helv_md"/>
                      </NavLinkAdv>
                    </div>
                  )
                })
              )
            }}
          </Query>
        
      </div>
    )
  }
}      

export default withSitecoreContext()(ArticleCategoriesNavigationV2);
