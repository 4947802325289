import React from 'react';
import ReactComponent from '../../ReactComponent';
import Moment from 'react-moment';
import NavLinkAdv from '../../utils/NavLinkAdv';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';
import { Query } from 'react-apollo';
import { loader as gqlLoader } from 'graphql.macro';
import { DotLoader } from 'react-spinners';
import Pagination from '../../utils/Pagination';

const PostsGridQuery = gqlLoader('./postsGridQuery.graphql');

class PostsGrid extends ReactComponent {
    constructor(props) {
        super(props);
        this.state = {
            after: '0',
            nbOfItemsToShow: parseInt(this.props.fields.postPerPageGrid.value),
            postTag: '',
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            postTag: window.location.href
                .substring(window.location.href.indexOf('=') + 1)
                .toLowerCase()
                .replace('#', ''),
        });
    }

    handleChange(data) {
        this.setState({
            after: data.toString(),
        });
    }

    render() {
        function SortByPostUpdate(x, y) {
            return x.item.date.value === y.item.date.value
                ? 0
                : x.item.date.value < y.item.date.value
                ? 1
                : -1;
        }

        if (this.state.postTag.includes('#')) {
            this.state.postTag = this.state.postTag.replace('#', '');
        }

        return (
            <Query
                query={PostsGridQuery}
                variables={{
                    indexname: this.indexName,
                    rootPath: 'sitecore/content/' + this.appName + '/',
                    first: this.state.nbOfItemsToShow,
                    after: this.state.after,
                    postmaintag: this.state.postTag,
                }}
            >
                {({ loading, error, data }) => {
                    if (loading)
                        return (
                            <div
                                className="sweet-loading"
                                style={{ marginTop: '50px', marginBottom: '50px' }}
                            >
                                <DotLoader
                                    sizeUnit={'px'}
                                    size={25}
                                    color={'#0057B4'}
                                    loading={!this.state.isLoaded}
                                />
                            </div>
                        );

                    if (error) return <div>Error: {error.message}</div>;

                    let totalItems = data.search.results.totalCount;

                    return (
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 main-wrap">
                                    <div className="main-content">
                                        <div className="posts" data-layout="grid">
                                            <div className="col-12 posts-wrap posts-layout-grid row">
                                                {data && data.search.results.items ? (
                                                    data.search.results.items
                                                        .sort(SortByPostUpdate)
                                                        .map((post, index) => (
                                                            <div
                                                                className=" col-md-3 col-sm-6 hentry"
                                                                key={index}
                                                            >
                                                                <div className="hentry-wrap">
                                                                    <div className="entry-featured">
                                                                        <NavLinkAdv
                                                                            to={
                                                                                post.item &&
                                                                                post.item.url
                                                                            }
                                                                        >
                                                                            <img
                                                                                loading="lazy"
                                                                                src={
                                                                                    post.item
                                                                                        .postThumbnailImageMedium &&
                                                                                    post.item
                                                                                        .postThumbnailImageMedium
                                                                                        .src
                                                                                }
                                                                                alt={''}
                                                                            />
                                                                        </NavLinkAdv>
                                                                    </div>
                                                                    <div className="entry-info">
                                                                        <div className="entry-header">
                                                                            <h2 className="entry-title">
                                                                                <NavLinkAdv
                                                                                    to={
                                                                                        post.item &&
                                                                                        post.item
                                                                                            .url
                                                                                    }
                                                                                >
                                                                                    {post.item
                                                                                        .postTitle &&
                                                                                        post.item
                                                                                            .postTitle
                                                                                            .value}
                                                                                </NavLinkAdv>
                                                                            </h2>
                                                                        </div>
                                                                        <div className="entry-content">
                                                                            {post.item
                                                                                .postShortDescription &&
                                                                                post.item
                                                                                    .postShortDescription
                                                                                    .value}
                                                                        </div>
                                                                        <div className="clearfix">
                                                                            <div className="entry-meta icon-meta">
                                                                                <span className="meta-date">
                                                                                    <i className="fa fa-calendar" />
                                                                                    <span>
                                                                                        <Moment format="MMM DD, YYYY">
                                                                                            {post
                                                                                                .item
                                                                                                .date &&
                                                                                                post
                                                                                                    .item
                                                                                                    .date
                                                                                                    .value}
                                                                                        </Moment>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div className="readmore-link">
                                                                                <NavLinkAdv
                                                                                    to={
                                                                                        post.item &&
                                                                                        post.item
                                                                                            .url
                                                                                    }
                                                                                >
                                                                                    {i18next.t(
                                                                                        'read-more'
                                                                                    )}
                                                                                </NavLinkAdv>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                ) : (
                                                    <p>There is no item to show.</p>
                                                )}
                                            </div>
                                            <Pagination
                                                totalItems={totalItems}
                                                nbOfItemsToShow={this.state.nbOfItemsToShow}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    }
}

export default withSitecoreContext()(PostsGrid);
