import React from 'react';
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import {DotLoader} from "react-spinners";
import NavLinkAdv from "../../utils/NavLinkAdv";

const ParentRouteQuery = gqlLoader('./query.graphql');

class ParentRoute extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          ListParents: ''
        };
    }

    render(){
        return(
            <Query query={ParentRouteQuery} variables={{indexname: this.indexName, rootpath: '/sitecore/content/' + this.appName + '/', currrentContextItem: this.props.CurrentId}}>
                {({ loading, error, data}) => {
                    if (loading)
                        return (
                            <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                                <DotLoader
                                    sizeUnit={"px"}
                                    size={50}
                                    color={'#0057B4'}
                                    loading={!this.state.isLoaded}
                                />
                            </div>
                        );
                    if (error)
                        return (
                            <div>Error: {error.message}</div>
                        );
                    return (
                        <>
                            <nav className={"breadcrumb__custom"}>
                                {data.breadcrumbs && JSON.parse(data.breadcrumbs).ListParents.map((item, index) => {
                                        if(item.Text !== "Gamme de produits" && item.Text !== "Votre besoin" && item.Text !== "Votre type de peau"){
                                            return(
                                                <React.Fragment key={index}>
                                                    <NavLinkAdv className="inactive" to={item.Link}>
                                                        {item.Text}
                                                    </NavLinkAdv>
                                                    <span className={'intera'}>
                                                        <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
                                                            <defs>
                                                                <filter id="filter_1">
                                                                    <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.43529412 0 0 0 0 0.43529412 0 0 0 0 0.43529412 0 0 0 1 0" />
                                                                </filter>
                                                                <path d="M0 0L4.26892 0L4.26892 7.22662L0 7.22662L0 0Z" transform="translate(7.4999996E-05 0.00018)" id="path_1" />
                                                            </defs>
                                                            <g id="IconChevron-Regular">
                                                                <g id="Group-3" transform="translate(13.5 11.25)" filter="url(#filter_1)">
                                                                    <path d="M0 0L4.26892 0L4.26892 7.22662L0 7.22662L0 0Z" transform="translate(7.4999996E-05 0.00018)" id="Clip-2" fill="none" fillRule="evenodd" stroke="none" />
                                                                    <g clipPath="url(#mask_1)">
                                                                        <path d="M0.449625 7.22662C0.334875 7.22662 0.219375 7.18237 0.131625 7.09462C-0.043875 6.91987 -0.043875 6.63413 0.131625 6.45937L2.99662 3.59363L0.170625 0.767625C-0.004875 0.592125 -0.004875 0.306375 0.170625 0.131625C0.346125 -0.043875 0.630375 -0.043875 0.806625 0.131625L4.26937 3.59363L0.767625 7.09462C0.679875 7.18237 0.564375 7.22662 0.449625 7.22662" transform="translate(7.4999996E-05 0.00018)" id="Fill-1" fill="#000000" fillRule="evenodd" stroke="none" />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </React.Fragment>
                                            )
                                        }
                                        
                                            
                                        
                                    })
                                }
                                <span className="active">{this.props.CurrentItemName}</span>
                            </nav>
                        </>
                    )
                }}
            </Query>
        );
    }

}
export default ParentRoute;
