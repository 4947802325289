import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { DotLoader } from 'react-spinners';
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import './articleCategoriesNav.scss';
import NavLinkAdv from "../../utils/NavLinkAdv";

const ArticleCategoriesNavigationQuery = gqlLoader('./query.graphql');

class ArticleCategoriesNavigation extends ReactComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    componentDidUpdate() {

        let getActiveItem = document.querySelectorAll('.lyam .active');
        // Hotfix bug active filter
        if (getActiveItem.length === 2) {
            document.querySelectorAll('.filtre a').forEach((item) => {
                if (item.innerText === 'TOUS LES ARTICLES') {
                    item.classList.remove('active');
                }
            });
        }
    }

    render() {
        return(
            <div className="row cat__custom__article lyam">
                <Query query={ArticleCategoriesNavigationQuery} variables={{indexname: this.indexName, rootpath: '/sitecore/content/' + this.appName + '/home'}}>
                    {({ loading, error, data}) => {
                        if (loading)
                            return (
                                <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                                    <DotLoader
                                        sizeUnit={"px"}
                                        size={50}
                                        color={'#0057B4'}
                                        loading={!this.state.isLoaded}
                                    />
                                </div>
                            );
                        if (error)
                            return (
                                <div>Error: {error.message}</div>
                            );
                             
                        return (
                            data &&
                            data.product.results.items.map(function (category, idx) {
                                if(category.item.articleTitle.value.length > 0){
                                    return (
                                        <React.Fragment key={idx}>
                                            <div className="filter_article_navigation filtre"> 
                                                <NavLinkAdv to={category.item && category.item.url} title="">
                                                    <Text tag="span" field={category.item && category.item.articleTitle}/>
                                                </NavLinkAdv>
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            })
                        )
                    }}
                </Query>
            </div>
        )
    }
}

export default withSitecoreContext()(ArticleCategoriesNavigation);
