import React from 'react';
import { Image, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from '../../ReactComponent';
import i18next from 'i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './relatedProductsCarousel.scss';
import StarRating from '../StarRating';
import ProductReviews from '../../utils/ProductReviews';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import NavLinkAdv from '../../utils/NavLinkAdv';
import { handleSliderButtonClick, productClickTagging } from '../Tagging';

const RelatedProductsCarouselQuery = gqlLoader('./RelatedProductsCarouselQuery.graphql');

class RelatedProductsCarousel extends ReactComponent {
    state = {
        isLoaded: false,
    };

    // If necessary, it give us one number after the "," to have half star
    calcNumberValue(value) {
        return Math.round(value * 10) / 10;
    }


    render() {
        const graphQLResult = this.props.relatedProductsCarouselQuery;
        const { datasource } = graphQLResult;

        if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
            const designVariant =
                typeof this.props.fields.designVariant === 'undefined'
                    ? ''
                    : this.props.fields.designVariant.value;
            const settings = {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                draggable: false,
                beforeChange: () => handleSliderButtonClick(this.props.sitecoreContext.route.fields.pageCategoryGTM.value, this.props.fields.Heading.value),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 520,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ],
            };


            return (
                <div className={'margin_custom rpc ' + designVariant}>
                    <div className={'row related__product__custom'}>
                        <div className="heading">
                            <Text field={this.props.fields.Heading} tag={'h2'} />
                            <Text field={this.props.fields.SubHeading} tag={'h3'} />
                        </div>
                        <div className="hide-left" />
                        <Slider {...settings} className={'sliderCustom'}>
                            {datasource.productList &&
                                datasource.productList.targetItems.map((item, index) => (
                                    <div
                                        key={`index-${index}`}
                                        className="portfolio-item text-cente productTag"
                                        data-gtm-observed="false"
                                        data-gtm-name={item.title.value}
                                        data-gtm-ean={item.codeEan.value}
                                        data-gtm-category={
                                            item.productCategoryGTM.value
                                        }
                                        data-gtm-rating={item.rating.numberValue}
                                        data-gtm-list={this.props.sitecoreContext.route.templateName === "App Route" ? "highlight-product" : "cross-sell"}
                                        data-gtm-position={index+1}
                                        onClick={() =>
                                            productClickTagging(
                                                item.title.value,
                                                item.codeEan.value,
                                                item.productCategoryGTM.value,
                                                item.rating.numberValue,
                                                this.props.sitecoreContext.route.templateName === "App Route" ? "highlight-product" : "cross-sell",
                                                index+1
                                            )
                                        }
                                    >
                                        <NavLinkAdv to={item.url} className="portfolio-link">
                                            <div className={'imgSlide'}>
                                                <Image loading="lazy" field={item.image1} />
                                            </div>
                                            <div className="portfolio-caption">
                                                <Text
                                                    field={item.title}
                                                    tag="h2"
                                                    className={'titleProduct'}
                                                />
                                                <Text
                                                    field={item.beneficeProduct}
                                                    tag="h3"
                                                    className={'beneficeProduct'}
                                                />
                                                {item.rating.numberValue !== 0 && (
                                                    <div className="stars">
                                                        <StarRating
                                                            value={this.calcNumberValue(
                                                                item.rating.numberValue
                                                            )}
                                                            isLittleSvg={true}
                                                        />{' '}
                                                        &nbsp;
                                                        <span>
                                                            {this.calcNumberValue(
                                                                item.rating.numberValue
                                                            )}
                                                        </span>{' '}
                                                        &nbsp;
                                                        <ProductReviews
                                                            productId={item.id
                                                                .replace(/-/g, '')
                                                                .toLowerCase()}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </NavLinkAdv>
                                        <NavLinkAdv to={item.url} className="btn btn_custom_oro">
                                            {i18next.t('see-more')}
                                        </NavLinkAdv>
                                    </div>
                                ))}
                        </Slider>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="component-no-datasource">
                    {i18next.t('no-datasource') +
                        ' ' +
                        this.props.rendering.componentName +
                        ' ' +
                        i18next.t('component')}
                </div>
            );
        }
    }
}

export default GraphQLData(RelatedProductsCarouselQuery, { name: 'relatedProductsCarouselQuery' })(
    RelatedProductsCarousel
);
