import React from 'react';
import { RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Query } from "react-apollo";
import { DotLoader } from 'react-spinners';
import {loader as gqlLoader} from "graphql.macro";
import moment from 'moment';
import Pagination from '../YllyPagination';
import { motion } from "framer-motion";
import "./articleNavigation.scss";

const CategoryQuery = gqlLoader('./categoriesquery.graphql');
const ArticleCategoriesQuery = gqlLoader('./articlesquery.graphql');

const ArticleNavigationV2 = () => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isLoaded2, setIsLoaded2] = React.useState(false);
    const [choosenCategoryId, setChoosenCategoryId] = React.useState("");
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [nbArticles, setNbArticles] = React.useState(null);
    const [after, setAfter] = React.useState('0');
    const [activePage, setActivePage] = React.useState(null);

    const handlePageChange = (data) => {
      setAfter((data - 1)  * itemsPerPage+ '');
      setActivePage(data);  
    }
  
    const changeItemsPerPage = (number) => {
      setItemsPerPage(number);
      setActivePage(1);
    }

    const HoveredButton = ({num}) => {
        const [isHovered, setIsHovered] = React.useState(false);
        return (
            <div 
                className={isHovered ? "paggingButtonHovered" : "paggingButton"} 
                onClick={ () => changeItemsPerPage(num)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)} 
            >
                <div className="paggingButtonText">{num}</div>
            </div>
        )
    }
  
    return (
        <div className="articleNavigation">
        
            <div style={{textAlign: 'center'}}>
                <h1>Nos catégories</h1>
            </div>
            <Query 
                query={CategoryQuery} 
                variables={{
                    indexname:  "sitecore_master_jss_master_index", 
                    rootpath: "sitecore/content/jss-master/jss-master/home"
                }}
            >
                

                {({ loading, error, data}) => {
                if (loading) {
                    return (
                        <div className='sweet-loading' 
                        style={{marginTop: '50px', marginBottom: '50px'}}>
                            <DotLoader
                            sizeUnit={"px"}
                            size={50}
                            color={'#0057B4'}
                            loading={!isLoaded}
                            />
                        </div>
                    );
                }
                   
                if (error) {
                    return (
                        <div>Error: {error.message}</div>
                    );
                }

                return (
                    <>
                    <div className="d-flex justify-content-around">
                        {
                            data.product.results.items.filter(item => item.item.articleTitle.value !== "").map(item => {
                                return (
                                    <div 
                                        key={Math.random().toString()} 
                                        style={{backgroundImage: `url(${item.item.articleImage.value})`, }}
                                        onClick={() => {
                                            setChoosenCategoryId(item.item.id)
                                        }}
                                        data-catimage={'blue'}
                                        className="categoryContainer"
                                    >
                                        <span className="categoryTitle" >{item.item.articleTitle.value}</span>
                                    </div>
                                )
                            })
                        }
                    </div>

                    </>

                )
                }} 
            </Query>

            <div className="d-flex justify-content-around clearfix">
                <HoveredButton num={2} />
                <HoveredButton num={5} />
                <HoveredButton num={10} />
                {/* <div className="btn btn-dark" onClick={ () => changeItemsPerPage('2')} className="paggingButton"><div className="numberOfItemsText">2</div></div>
                <div className="btn btn-dark" onClick={ () => changeItemsPerPage('5')} className="paggingButton"><div className="numberOfItemsText">5</div></div> */}
                {/* <div className="btn btn-dark" onClick={ () => changeItemsPerPage('10')} className="paggingButton"><div className="numberOfItemsText">10</div></div> */}
            </div>
            <div className="nosArticles" >
                <h1>Nos Articles</h1>
            </div>
            <Query 
                query={ArticleCategoriesQuery } 
                variables={{
                    indexname: "sitecore_master_jss_master_index", 
                    categoryId: choosenCategoryId, // if "" ça renvoie tous les articles
                    first: itemsPerPage,
                    after: after
                }}
            >
            
            {({ loading, error, data}) => {
                if (loading) {
                    return(
                        <div className='sweet-loading' style={{marginTop:'50px', marginBottom: '50px'}}>
                            <DotLoader
                                sizeUnit={"px"}
                                size={50}
                                color={'#0057B4'}
                                loading={!isLoaded2}
                            />
                        </div>
                    );
                }
                
                if (error) {
                    return (
                        <div>Error: {error.message}</div>
                    );
                }
                if (data) {
                    setNbArticles(data.product.results.totalCount);
                }

                return (
                    <>
                        {
                             Math.ceil(nbArticles / itemsPerPage) > 1 
                            ?  
                                <motion.div
                                    whileHover={{scale: 2}}
                                >
                                    <Pagination 
                                        activePage={activePage} 
                                        handleChange={handlePageChange} 
                                        nbPage={Math.ceil(nbArticles / itemsPerPage)}
                                        activeBackgroundColor='lightgreen'
                                        hoverBackgroundColor='lightgray'
                                        fontSize={10}
                                        digitWidth={15}
                                        spacing={15}
                                        colors={{iconColor: 'black'}}
                                        iconSize={{large: 'fa-2x', medium: 'fa-lg', small: 'fa-sm' }}
                                    />
                                </motion.div>    
                            : null
                        }
                        
                            <div className="gridContainer" >
                            {
                                data.product.results.items.map((item, i) => {
                                    return (
                                        <div className='border border-light articleContainer'  key={Math.random().toString()} >
                                            <img loading="lazy" source={item.item.articleImage.src} className="articleImage" ></img>
                                                <span style={{marginLeft: '11%'}}>
                                                    {moment(item.item.date).format('DD-MM-YYYY')}
                                                </span>
                                                <div className="articleTitle">
                                                    {item.item.articleTitle?.value}
                                                </div>
                                                <div className="seeMore" >
                                                    See More
                                                </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                    </>
                )
            }}
            </Query>
        </div>
    )
}

export default withSitecoreContext()(ArticleNavigationV2);
