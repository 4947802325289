import React from 'react';

const starEmpty = (isLittleSvg) => {
  if(isLittleSvg){
    return <svg width="17px" height="15px" viewBox="0 0 16 15" version="1.1">
      <path d="M15.345 5.69378C15.3061 5.56831 15.1909 5.48268 15.0616 5.48268L9.63977 5.48268L7.96341 0.210259C7.92281 0.0856333 7.80763 6.23937e-06 7.68001 6.23937e-06C7.55074 -0.000841553 7.43722 0.0847855 7.39661 0.209411L5.70865 5.48268L0.298392 5.48268C0.169123 5.48268 0.0539401 5.56831 0.0149935 5.69378C-0.0256104 5.81756 0.0183081 5.95745 0.121889 6.03459L4.50131 9.29775L2.81335 14.5998C2.7744 14.7245 2.81832 14.8627 2.9219 14.9415C3.02548 15.0187 3.16801 15.0187 3.27159 14.9415L7.68001 11.6673L12.0777 14.9415C12.129 14.9797 12.1904 15 12.2542 15C12.3138 15 12.3751 14.9797 12.4282 14.9415C12.5318 14.8627 12.5757 14.7253 12.5351 14.5998L10.8488 9.29775L15.2365 6.03459C15.3417 5.95745 15.3856 5.81841 15.345 5.69378" id="Fill-1-Copy-5" fill="#CCCCCC" fillRule="evenodd" stroke="none" />
    </svg>
  }else{
    return <svg width="36px" height="33px" viewBox="0 0 16 15" version="1.1">
      <path d="M15.345 5.57535C15.3061 5.45249 15.1909 5.36864 15.0616 5.36864L9.63977 5.36864L7.96341 0.205885C7.92281 0.0838521 7.80763 6.10959e-06 7.68001 6.10959e-06C7.55074 -0.000824049 7.43722 0.083022 7.39661 0.205055L5.70865 5.36864L0.298392 5.36864C0.169123 5.36864 0.0539401 5.45249 0.0149935 5.57535C-0.0256104 5.69655 0.0183081 5.83353 0.121889 5.90907L4.50131 9.10435L2.81335 14.2962C2.7744 14.4182 2.81832 14.5535 2.9219 14.6307C3.02548 14.7063 3.16801 14.7063 3.27159 14.6307L7.68001 11.4246L12.0777 14.6307C12.129 14.6681 12.1904 14.688 12.2542 14.688C12.3138 14.688 12.3751 14.6681 12.4282 14.6307C12.5318 14.5535 12.5757 14.419 12.5351 14.2962L10.8488 9.10435L15.2365 5.90907C15.3417 5.83353 15.3856 5.69738 15.345 5.57535" id="Fill-1-Copy-5" fill="#CCCCCC" fillRule="evenodd" stroke="none" />
    </svg>
  }
}

const starHalf = (isLittleSvg) => {
  if(isLittleSvg){
    return <svg width="17px" height="15px" viewBox="0 0 16 15" version="1.1">
      <g id="SvgHalf">
        <path d="M7.96388 8C7.92493 7.87453 8.09315 7 7.96388 7L7.96388 5.48267L7.96342 0.210259C7.92282 0.0856333 7.80763 6.23937e-06 7.68002 6.23937e-06C7.55075 -0.000841553 7.43723 0.0847855 7.39662 0.209411L5.70866 5.48268L0.298392 5.48268C0.169123 5.48268 0.0539402 5.56831 0.0149935 5.69378C-0.0256104 5.81756 0.0183081 5.95745 0.12189 6.0346L4.50131 9.29775L2.81335 14.5998C2.7744 14.7245 2.81832 14.8627 2.9219 14.9415C3.02549 15.0187 3.16801 15.0187 3.2716 14.9415L7.68002 11.6673L7.96387 11C8.01524 11.0382 7.90006 11 7.96387 11C8.02353 11 7.91084 10.5382 7.96387 10.5C8.06745 10.4212 8.00448 10.1255 7.96387 10L7.96387 9.29785L7.96387 8.5C8.06912 8.42285 7.96388 8 7.96388 8Z" id="Fill-1" fill="#0057B4" fillRule="evenodd" stroke="none" />
        <path d="M7.7217 5.69378C7.68275 5.56831 7.56757 5.48268 7.4383 5.48268L2.01643 5.48268L0.340065 0.210259C0.29946 0.0856332 0.184277 6.18268e-06 0.0566645 6.18268e-06C-0.0726048 -0.00084161 0.0966432 0.0855796 0.0560393 0.210205L0.0583664 5.48267L0.0579924 5.48267C0.012199 5.48267 0.0583664 5.48267 0.0583664 5.48267C0.0583664 5.48267 0.0522264 7.28732 0.0566406 7.5C0.015625 7.60107 -0.0470606 7.42285 0.0565211 7.5L0.0563394 8L0.0563394 9.29785C0.0173926 9.42248 0.0683588 10.74 0.0585937 11C0.0488284 11.281 -0.0472425 11.0772 0.0563394 11L0.0566645 11.6673L4.45432 14.9415C4.5057 14.9797 4.56702 15 4.63082 15C4.69049 15 4.75181 14.9797 4.80484 14.9415C4.90842 14.8627 4.95234 14.7253 4.91174 14.5998L3.22543 9.29775L7.61314 6.03459C7.71838 5.95745 7.7623 5.81841 7.7217 5.69378" transform="translate(7.6210938 0)" id="Fill-1-Copy-6" fill="#CCCCCC" fillRule="evenodd" stroke="none" />
      </g>
    </svg>
  }else{
    return <svg width="36px" height="33px" viewBox="0 0 16 15" version="1.1">
      <g id="SvgHalf">
        <path d="M7.96388 8C7.92493 7.87453 8.09315 7 7.96388 7L7.96388 5.48267L7.96342 0.210259C7.92282 0.0856333 7.80763 6.23937e-06 7.68002 6.23937e-06C7.55075 -0.000841553 7.43723 0.0847855 7.39662 0.209411L5.70866 5.48268L0.298392 5.48268C0.169123 5.48268 0.0539402 5.56831 0.0149935 5.69378C-0.0256104 5.81756 0.0183081 5.95745 0.12189 6.0346L4.50131 9.29775L2.81335 14.5998C2.7744 14.7245 2.81832 14.8627 2.9219 14.9415C3.02549 15.0187 3.16801 15.0187 3.2716 14.9415L7.68002 11.6673L7.96387 11C8.01524 11.0382 7.90006 11 7.96387 11C8.02353 11 7.91084 10.5382 7.96387 10.5C8.06745 10.4212 8.00448 10.1255 7.96387 10L7.96387 9.29785L7.96387 8.5C8.06912 8.42285 7.96388 8 7.96388 8Z" id="Fill-1" fill="#0057B4" fillRule="evenodd" stroke="none" />
        <path d="M7.7217 5.69378C7.68275 5.56831 7.56757 5.48268 7.4383 5.48268L2.01643 5.48268L0.340065 0.210259C0.29946 0.0856332 0.184277 6.18268e-06 0.0566645 6.18268e-06C-0.0726048 -0.00084161 0.0966432 0.0855796 0.0560393 0.210205L0.0583664 5.48267L0.0579924 5.48267C0.012199 5.48267 0.0583664 5.48267 0.0583664 5.48267C0.0583664 5.48267 0.0522264 7.28732 0.0566406 7.5C0.015625 7.60107 -0.0470606 7.42285 0.0565211 7.5L0.0563394 8L0.0563394 9.29785C0.0173926 9.42248 0.0683588 10.74 0.0585937 11C0.0488284 11.281 -0.0472425 11.0772 0.0563394 11L0.0566645 11.6673L4.45432 14.9415C4.5057 14.9797 4.56702 15 4.63082 15C4.69049 15 4.75181 14.9797 4.80484 14.9415C4.90842 14.8627 4.95234 14.7253 4.91174 14.5998L3.22543 9.29775L7.61314 6.03459C7.71838 5.95745 7.7623 5.81841 7.7217 5.69378" transform="translate(7.6210938 0)" id="Fill-1-Copy-6" fill="#CCCCCC" fillRule="evenodd" stroke="none" />
      </g>
    </svg>
  }
}

const starFull = (isLittleSvg) => {
  if(isLittleSvg){
    return <svg width="17px" height="15px" viewBox="0 0 17 15" version="1.1">
      <path d="M16.3041 5.69378C16.2627 5.56831 16.1403 5.48268 16.003 5.48268L10.2423 5.48268L8.46112 0.210259C8.41798 0.0856333 8.2956 6.23937e-06 8.16001 6.23937e-06C8.02266 -0.000841553 7.90204 0.0847855 7.8589 0.209411L6.06544 5.48268L0.317042 5.48268C0.179693 5.48268 0.0573113 5.56831 0.0159306 5.69378C-0.027211 5.81756 0.0194524 5.95745 0.129508 6.03459L4.78264 9.29775L2.98918 14.5998C2.9478 14.7245 2.99446 14.8627 3.10452 14.9415C3.21457 15.0187 3.36601 15.0187 3.47607 14.9415L8.16001 11.6673L12.8325 14.9415C12.8871 14.9797 12.9523 15 13.0201 15C13.0834 15 13.1486 14.9797 13.2049 14.9415C13.315 14.8627 13.3617 14.7253 13.3185 14.5998L11.5268 9.29775L16.1888 6.03459C16.3006 5.95745 16.3472 5.81841 16.3041 5.69378" id="Fill-1-Copy-4" fill="#0057B4" fillRule="evenodd" stroke="none" />
    </svg>
  }else{
    return <svg width="36px" height="33px" viewBox="0 0 36 33" version="1.1">
      <path d="M35.6652 12.1961C35.5747 11.9273 35.307 11.7439 35.0065 11.7439L22.4049 11.7439L18.5087 0.450374C18.4143 0.183426 18.1466 1.33647e-05 17.85 1.33647e-05C17.5496 -0.00180261 17.2857 0.181611 17.1913 0.448558L13.2682 11.7439L0.693528 11.7439C0.393078 11.7439 0.125369 11.9273 0.0348482 12.1961C-0.0595241 12.4612 0.042552 12.7608 0.283298 12.9261L10.462 19.9158L6.53883 31.2729C6.44831 31.5398 6.55039 31.8358 6.79114 32.0047C7.03188 32.1699 7.36315 32.1699 7.60389 32.0047L17.85 24.9914L28.0711 32.0047C28.1905 32.0864 28.3331 32.13 28.4814 32.13C28.62 32.13 28.7626 32.0864 28.8858 32.0047C29.1266 31.8358 29.2286 31.5416 29.1343 31.2729L25.2149 19.9158L35.4129 12.9261C35.6575 12.7608 35.7596 12.463 35.6652 12.1961" id="Fill-1-Copy-4" fill="#0057B4" fillRule="evenodd" stroke="none" />
    </svg>
  }
}

const StarRating = ({value, isLittleSvg}) => {
  let rating = <>{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starFull(isLittleSvg)}</>;
  let note = parseInt(value, 10)

  switch(true) {
    case note === 0:
      rating = <>{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}</>;
      break;
    case note > 0 && note < 1:
      rating = <>{starHalf(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}</>;
      break;
    case note === 1:
      rating = <>{starFull(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}</>;
      break;
    case note > 1 && note < 2:
      rating = <>{starFull(isLittleSvg)}{starHalf(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}</>;
      break;
    case note === 2:
      rating = <>{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}</>;
      break;
    case note > 2 && note < 3:
      rating = <>{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starHalf(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}</>;
      break;
    case note === 3:
      rating = <>{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}</>;
      break;
    case note > 3 && note < 4:
      rating = <>{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starHalf(isLittleSvg)}{starEmpty(isLittleSvg)}</>;
      break;
    case note === 4:
      rating = <>{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starEmpty(isLittleSvg)}</>;
      break;
    case note > 4 && note < 5:
      rating = <>{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starHalf(isLittleSvg)}</>;
      break;
    case note === 5:
      rating = <>{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starFull(isLittleSvg)}{starFull(isLittleSvg)}</>;
      break;
    default:
      rating = <>{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}{starEmpty(isLittleSvg)}</>;
      break;
  }
  return(
      <p>
        {rating}
      </p>
  );

}

export default StarRating;
