import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Moment from 'react-moment';
import { DotLoader } from 'react-spinners';
import { Query } from 'react-apollo';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

const RecentPostsQuery = gqlLoader('./RecentPostsQuery.graphql');

class RecentPosts extends ReactComponent {
    render() {
        const datasource = this.props.fields || {};
        if (datasource != undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
            let PostsNumber = this.props.fields.PostsNumber.numberValue;
            if (
                PostsNumber === undefined ||
                PostsNumber === null ||
                isNaN(PostsNumber) ||
                PostsNumber === 0 ||
                PostsNumber < 0
            )
                PostsNumber = 3;

            function SortByPostUpdate(x, y) {
                return x.item.date.value === y.item.date.value
                    ? 0
                    : x.item.date.value < y.item.date.value
                    ? 1
                    : -1;
            }

            return (
                <div
                    className={
                        'RecentPosts widget widget-post-thumbnail ' +
                        this.props.fields.designVariant.value
                    }
                >
                    <h3 className="widget-title">
                        <span>
                            {' '}
                            <Text field={this.props.fields.title} />
                        </span>
                    </h3>
                    <Query
                        query={RecentPostsQuery}
                        variables={{
                            indexname: this.indexName,
                            rootPath: 'sitecore/content/' + this.appName + '/',
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading)
                                return (
                                    <div
                                        className="sweet-loading"
                                        style={{ marginTop: '50px', marginBottom: '50px' }}
                                    >
                                        <DotLoader
                                            sizeUnit={'px'}
                                            size={50}
                                            color={'#0057B4'}
                                            loading={loading}
                                        />
                                    </div>
                                );
                            if (error) return <div>Error: {error.message}</div>;

                            return (
                                <ul className="posts-thumbnail-list">
                                    {data.search.results.items &&
                                        data.search.results.items
                                            .sort(SortByPostUpdate)
                                            .slice(0, PostsNumber)
                                            .map((post, index) => (
                                                <li key={index}>
                                                    <div className="posts-thumbnail-image">
                                                        <NavLinkAdv to={post.item.url}>
                                                            <img
                                                                loading="lazy"
                                                                src={
                                                                    post.item
                                                                        .postThumbnailImageSmallSquared
                                                                        .src
                                                                }
                                                                alt=""
                                                                width="60"
                                                                height="60"
                                                            ></img>
                                                        </NavLinkAdv>
                                                    </div>
                                                    <div className="posts-thumbnail-content">
                                                        <h4>
                                                            <NavLinkAdv to={post.item.url}>
                                                                {post.item.postTitle.value}
                                                            </NavLinkAdv>
                                                        </h4>
                                                        <div className="posts-thumbnail-meta">
                                                            <span>
                                                                <Moment format="MMM DD, YYYY">
                                                                    {post.item.date.value}
                                                                </Moment>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                </ul>
                            );
                        }}
                    </Query>
                </div>
            );
        } else {
            return (
                <div className="component-no-datasource">
                    {i18next.t('no-datasource') +
                        ' ' +
                        this.props.rendering.componentName +
                        ' ' +
                        i18next.t('component')}
                </div>
            );
        }
    }
}

export default withSitecoreContext()(RecentPosts);
